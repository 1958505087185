import { RequestOption, useApiRequest } from "@/hooks/api/useApiRequest";
import { LiveBookingRequest, SuccessResponse } from "@/lib/aspida/api/@types";
import { client } from "@/lib/aspida/client";

/**
 * health check API hook
 * @returns ヘルスチェックAPIモジュール
 */
export const useLiveBookingApi = () => {
    const { request, apiRequestState } = useApiRequest<SuccessResponse>()

    return {
        submit: (
            data: LiveBookingRequest,
            option?: RequestOption<SuccessResponse>
        ) => {
            return request(async () => {
                return client.v1.live_booking.post({
                    body: data,
                })
            }, option)
        },
        liveBookingApiState: apiRequestState,
    }
}
